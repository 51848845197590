<template>
  <div>
    <a-card title="来料检验报告详情">
      <template slot="extra">
        <a-space>
          <a-button type="primary" ghost icon="printer" v-print="'#printContent'"> 打印</a-button>
          <a-button type="primary" ghost icon="left" @click="$router.go(-1)">返回</a-button>
        </a-space>
      </template>

      <section id="printContent">
        <a-spin :spinning="retrieveLoading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="检验报告单号">
              {{ dataItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="关联单号">
              <span v-if="dataItem.stock_in_order_item.type === 'purchase'">
                {{ dataItem.stock_in_order_item.purchase_order_item.number }}
              </span>
              <span v-if="dataItem.stock_in_order_item.type === 'production_stock_in'">
                {{ dataItem.stock_in_order_item.production_order_item.number }}
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="检验日期">
              {{ dataItem.inspection_date }}
            </a-descriptions-item>
            <a-descriptions-item label="检验人">
              {{ dataItem.inspector_item.name }}
            </a-descriptions-item>
            <a-descriptions-item label="检验依据">
              {{ dataItem.inspection_basis.join(", ") }}
            </a-descriptions-item>
            <a-descriptions-item label="检验外观">
              {{ dataItem.inspection_appearance }}
            </a-descriptions-item>
            <a-descriptions-item label="检验规格">
              {{ dataItem.inspection_dimensions }}
            </a-descriptions-item>
            <a-descriptions-item label="检验功能">
              {{ dataItem.inspection_function }}
            </a-descriptions-item>
            <a-descriptions-item label="检验测试">
              {{ dataItem.inspection_testing }}
            </a-descriptions-item>
            <a-descriptions-item label="检验试装">
              {{ dataItem.inspection_installation }}
            </a-descriptions-item>

            <a-descriptions-item label="检验结果">
              {{ dataItem.inspection_result_display }}
            </a-descriptions-item>
            <!-- <a-descriptions-item label="检验报告">
              {{ dataItem.inspection_content }}
            </a-descriptions-item> -->
            <a-descriptions-item label="创建人">
              {{ dataItem.creator_item.name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ dataItem.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="作废状态">
              {{ dataItem.is_void ? "已作废" : "已完成" }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ dataItem.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="附件">
              <div v-for="fileItem in dataItem.inspection_file_items" :key="fileItem.id">
                <a :href="fileItem.file" target="_blank" style="margin-right: 16px">{{ fileItem.name }}</a>
              </div>
            </a-descriptions-item>
          </a-descriptions>

          <!-- <a-divider orientation="left">检验标准</a-divider>
          <a-table
            size="small"
            :columns="inspectionStandardsHearder"
            :dataSource="dataItem.inspection_standards"
            :pagination="false"
            style="width: 480px"
          >
          </a-table> -->

          <a-divider orientation="left" style="margin-top: 30px">检验物料</a-divider>
          <a-table
            rowKey="id"
            size="small"
            :columns="tableColumns"
            :dataSource="dataItem.inspection_product_items"
            :pagination="false"
          />
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { inspectionReportRetrieve } from "@/api/inspection";
import JsBarcode from "jsbarcode";

export default {
  data() {
    return {
      dataItem: {},
      retrieveLoading: false,
      tableColumns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "物料名称",
          dataIndex: "product_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "物料编号",
          dataIndex: "product_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "物料条码",
          dataIndex: "product_barcode",
          customRender: (_, item) => item.goods_item.barcode,
        },
        {
          title: "物料规格",
          dataIndex: "product_spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "来料数量",
          dataIndex: "total_quantity",
        },
        {
          title: "抽检数量",
          dataIndex: "inspection_quantity",
        },
        {
          title: "合格数量",
          dataIndex: "qualified_quantity",
        },
        {
          title: "不合格数量",
          dataIndex: "unqualified_quantity",
        },
      ],

      inspectionStandardsHearder: [
        {
          title: "AQL",
          dataIndex: "aql",
        },
        {
          title: "CR:0.00",
          dataIndex: "cr",
        },
        {
          title: "MAJ:1.5",
          dataIndex: "maj",
        },
        {
          title: "MIN:2.5",
          dataIndex: "min",
        },
      ],
    };
  },
  methods: {
    initData() {
      this.retrieveLoading = true;
      inspectionReportRetrieve({ id: this.$route.query.id })
        .then((data) => {
          this.dataItem = data;
          this.getJsBarcode(this.dataItem.number);
        })
        .finally(() => {
          this.retrieveLoading = false;
        });
    },
    getJsBarcode(number) {
      JsBarcode("#barcode", number, {
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
